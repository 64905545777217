import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutes, routingComponents } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StylesDirectivesModule } from './directives/cursor-pointer.directive';
import { AppStorageModule } from './modules/app-storage/app-storage';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './components/app/app.component';

import { UserService } from './services/user.service';
import { StructureService } from './services/structure.service';
import { SearchModule } from 'src/modules/search/search.module';
import { EUSignModule } from 'src/modules/eu-sign/eu-sign.module';

@NgModule({
  declarations: [AppComponent, routingComponents],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    AppRoutes,
    BrowserAnimationsModule,

    StylesDirectivesModule,

    MatSnackBarModule,
    SearchModule.forRoot(),
    AppStorageModule.forRoot(),
    EUSignModule.forRoot(),
  ],
  providers: [UserService, StructureService],
})
export class AppModuleShared {}
