import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { IElementType, ElementTypeMapper } from '../../admin-app/models/elementType';

import EntityUtils from '../utils/entity-utils';
import DynamicFromGroupUtils from '../utils/dynamic-from-group.utils';

import 'rxjs/add/operator/map';

@Injectable()
export class StructureService {
    baseUrl: string;
    constructor(private http: HttpClient,
        @Inject('API_ENDPOINT') private apiEndpoint: string) {
        this.baseUrl = `${this.apiEndpoint}/structure`;
    }

    // createElementType(elementType: IElementType) {
    //     return this.http.post<any>(`${this.baseUrl}/element-type`, ElementTypeMapper.toEntity(elementType))
    //         .map(res => ElementTypeMapper.toModel(res));
    // }

    // updateElementType(elementType: IElementType) {
    //     return this.http.put<any>(`${this.baseUrl}/element-type`, ElementTypeMapper.toEntity(elementType));
    // }

    // elementTypes(): Observable<any> {
    //     return this.http.get(`${this.baseUrl}/element-types`).map((res: any) => {
    //         return res.map((et: any) => {
    //             return ElementTypeMapper.toModel(et);
    //         });
    //     });
    // }

    // getElementTypeById(elementTypeId: string): Observable<IElementType> {
    //     return this.http.get(`${this.baseUrl}/element-type/${elementTypeId}`)
    //         .map((res: any) => ElementTypeMapper.toModel(res));
    // }

    // getElementTypeAvailabelTables(elementTypeId: string): Observable<any> {
    //     return this.http.get(`${this.baseUrl}/element-type/${elementTypeId}/availabel-tables`);
    // }

    // updateTables(elementTypeId: string, tables: string[]) {
    //     return this.http.post(`${this.baseUrl}/element-type/${elementTypeId}/update-tables`, tables);
    // }

    // getElementTypeByName(typeName: string): Observable<IElementType> {
    //     return this.http.get(`${this.baseUrl}/element-type/name/${typeName}`)
    //         .map((res: any) => ElementTypeMapper.toModel(res));
    // }

    elementTypesProperties(id: string) {
        return this.http.get(`${this.baseUrl}/element-type/${id}/properties`);
    }

    elementTypesPropertiesGroup(id: string) {
        return this.elementTypesProperties(id).map(res => DynamicFromGroupUtils.keyValueToDynamicForm(res));
    }

    elementTypePropertiesConfiguration(id: string) {
        return this.http.get(`${this.baseUrl}/element-type/${id}/properties-config`);
    }

    createElementTypeProperty(id: string, name: string, label: string, dataType: string,
        inputType: string, maxLength: number, required: boolean, isUnique: boolean, branchUnique: boolean) {
        return this.http.post<any>(`${this.baseUrl}/element-type/${id}/property/add`,
            { id, name, label, dataType, inputType, maxLength, required, isUnique, branchUnique }
        );
    }

    removeElementTypeProperty(id: string, name: string) {
        return this.http.post<any>(`${this.baseUrl}/element-type/${id}/property/${name}/remove`, {});
    }

    createElement(parentId: string, isCollection: boolean, isVirtual: boolean, elementTypeId: string, properties: any) {
        return this.http.post<any>(`${this.baseUrl}/element`,
            {
                parentId: parentId,
                elementTypeId,
                isCollection,
                isVirtual,
                properties: EntityUtils.toLowerKeys(properties)
            });
    }

    elementDescendants(elementId?: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/element/descendants/${elementId || ''}`);
    }

    getBranchByChild(elementId: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/element/branch/child/${elementId}`);
    }

    moveElement(elementId: string, targetElementId: string): Observable<any> {
        return this.http.put(`${this.baseUrl}/element/${elementId}/move-to/${targetElementId}`, {});
    }

    elementPropValue(key: string, element: any) {
        const properties = element.jsondataobject
            ? element.jsondataobject.properties
            : element.properties;

        if (properties == null)
            return null;

        return properties[key];
    }

    treeLabel(element: any) {
        const elementTypeName = element.basetypename
            ? element.basetypename
            : element.typename;
        let name;

        switch (elementTypeName) {
            //case 'Address':
            //    name = `${elementTypeName}`;
            //    break;

            case 'User':
                name = `${element.typename} - ` + (this.elementPropValue('email', element) || this.elementPropValue('phone', element));
                break;

            default:
                name = this.elementPropValue('name', element) || elementTypeName;
                break;
        }

        return `${element.iscollection ? element.typename + ' - collection' : name}`;
    }

    treeIcon(element: any) {
        if (element.iscollection)
            return 'fa fa-database';

        if (element.isroot)
            return 'fa fa-sitemap';

        switch (element.typename.toLowerCase()) {
            case 'user':
                return 'fa fa fa-user';

            case 'group':
                return 'fa fa-cubes';

            case 'datatable':
                return 'fa fa-table';

            case 'room':
                return 'fa fa-home';

            case 'googlemap':
                return 'fa fa-map-o';

            case 'address':
                return 'fa fa-map-marker ';

            default:
                return 'fa fa-folder';
        }
    }

    isLeaf(element: any) {
        const elementType = element.elementtype ? element.elementtype : element;
        if (element.iscollection)
            return false;

        switch (elementType.typename.toLowerCase()) {
            //case 'user':
            case 'datatable':
            case 'address':
                return true;

            case 'googlemap':
                return 'fa fa-map-o';

            default:
                return false;
        }
    }

    isUserType(element: any): boolean {
        if (element == null)
            return false;

        return element.basetypename.toLowerCase() === 'user' || element.typename.toLowerCase() === 'user';
    }
}
