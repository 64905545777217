import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
})
export class DialogComponent {
  buttons: any[];
  icon: any;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.buttons = data.buttons;
    if (data.level === 'error') {
      this.icon = {
        icon: 'error',
        color: 'red',
      };
    }
    if (data.level === 'warning') {
      this.icon = {
        icon: 'warning',
        color: 'goldenrod',
      };
    }
  }

  buttonClick(btnIndex: number) {
    this.dialogRef.close(btnIndex);
  }
}
