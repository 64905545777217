import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

declare const EndUser: any;
declare const EndUserConstants: any;
declare const EndUserKeyMedia: any;

@Component({
  selector: 'app-eu-sign',
  templateUrl: './eu-sign.component.html',
})
export class EUSignComponent implements OnInit {
  private euSign: any;
  saved = false;

  subject: any = {};
  certificates: any[];

  public onPrivateKeyVerified = new EventEmitter<any>();
  public onClose = new EventEmitter<any>();
  public onSignBroken = new EventEmitter<any>();
  public onSignDelete = new EventEmitter<any>();

  constructor(private _http: HttpClient) {}

  ngOnInit() {
    // this.init();

    const SIGN_WIDGET_PARENT_ID = 'sign-widget-parent';
    const SIGN_WIDGET_ID = 'sign-widget';
    const SIGN_WIDGET_URI = 'https://eu.iit.com.ua/sign-widget/v20200922/';

    this.euSign = new EndUser(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      SIGN_WIDGET_URI,
      EndUser.FormType.ReadPKey
    );

    this.euSign
      .ReadPrivateKey()
      .then((data) => {
        if (data && data.length > 0) {
          this._displayUeInfo(data);
          this.saveSign();
          debugger;
          this.sign('3170916835').subscribe((res) => {
            const _params = new HttpParams();
            _params.append('sign', res);
            this._http
              .get(
                'https://api.unisoft.com.ua/api/v1/taxCabinet/payerCard?sign=' +
                  res
              )
              .subscribe((res) => console.log(res));
          });
        }
      })
      .catch((e) => {
        alert(
          'Виникла помилка при зчитуванні ос. ключа. ' +
            'Опис помилки: ' +
            (e.message || e)
        );
      });
  }

  private _displayUeInfo(data: any) {
    if (data && data.length > 0) {
      this.certificates = data;
      const certInfo = data[0].infoEx;

      this.subject.subjOrg = certInfo.subjOrg;
      this.subject.subjCN = certInfo.subjCN;
      this.subject.subjTitle = certInfo.subjTitle;
      this.subject.subjDRFOCode = certInfo.subjDRFOCode;
      this.subject.subjEDRPOUCode = certInfo.subjEDRPOUCode;
      this.subject.subjFullName = certInfo.subjFullName;

      console.log(this.subject);
    }
    document.getElementById('sign-widget-parent').style.display = 'none';
    document.getElementById('sign-info').style.display = 'block';
  }

  saveSign() {
    this.saved = true;
    this.onPrivateKeyVerified.emit();
    this.close();
  }

  deleteSign() {
    this.saved = false;
    this.euSign.destroy();
    this.euSign = null;
    this.certificates = null;
    this.onSignDelete.emit();
  }

  show() {
    document.getElementById('sign-widget-container').style.display = 'block';
    if (this.saved) {
      document.getElementById('sign-info').style.display = 'block';
    } else {
      document.getElementById('sign-widget-parent').style.display = 'block';
    }
  }

  close() {
    document.getElementById('sign-widget-parent').style.display = 'none';
    document.getElementById('sign-widget-container').style.display = 'none';
    document.getElementById('sign-info').style.display = 'none';

    this.onClose.emit();

    if (!this.saved) {
      this.deleteSign();
    }
  }

  public sign(data: string): Observable<string> {
    if (!this.euSign) {
      return of(null);
    }

    const previousSign = null;
    const external = false;
    const asBase64String = true;
    const signAlgo = EndUser.SignAlgo.DSTU4145WithGOST34311;
    const signType = EndUser.SignType.CAdES_T;

    return new Observable((observer) => {
      this.euSign
        .SignData(data, external, asBase64String, signAlgo, null, signType)
        .then((res: string) => {
          console.log(res);
          observer.next(res);
        })
        .catch((error: any) => {
          if (error.code === 50) {
            this.onSignBroken.emit();
          }
          debugger;
          observer.next(null);
        });
    });
  }

  public organization(): any {
    if (!this.euSign) {
      return null;
    }

    let companyType = '';
    let companyName = '';
    if (this.subject.subjOrg == 'ФІЗИЧНА ОСОБА') {
      companyType = 'ФІЗИЧНА ОСОБА';
      companyName = this.subject.subjCN;
    } else {
      const companyNameParts = this.subject.subjOrg.split(' ');
      if (companyNameParts.length > 0) {
        companyType = companyNameParts[0];
        companyName = this.subject.subjOrg.replace(companyType, '');
      } else {
        companyName = this.subject;
      }
    }

    return {
      subjectName: companyName,
      subjectType: companyType,
      director: this.subject.subjFullName,
      subjectCode: this.subject.subjEDRPOUCode || this.subject.subjDRFOCode,
      isOrganization:
        this.subject.subjOrg !== 'ФІЗИЧНА ОСОБА' &&
        this.subject.subjEDRPOUCode !== '',
    };
  }

  // ==============================================================

  // Налаштування бібліотеки
  euSettings = {
    language: 'uk',
    encoding: 'utf-8',
    httpProxyServiceURL: 'Server/ProxyHandler.php',
    directAccess: true,
    CAs: '../assets/data/CAs.json',
    CACertificates: '../assets/data/CACertificates.p7b',
    allowedKeyMediaTypes: [
      'е.ключ ІІТ Алмаз-1К',
      'е.ключ ІІТ Кристал-1',
      'ID-карта громадянина (БЕН)',
      'е.ключ ІІТ Алмаз-1К (PKCS#11)',
      'е.ключ ІІТ Кристал-1 (PKCS#11)',
    ],
  };

  // Бібліотека для роботи з файловими ключами, що не потребує
  // встановлення додатково ПЗ
  euSignFile: any; // = new EndUser(null, EndUserConstants.EndUserLibraryType.JS);

  // Бібліотека для роботи з аппаратними носіями, що потребує
  // встановлення додатково ПЗ бібліотек web-підпису, web-розширення для браузера
  euSignKeyMedia: any; // = new EndUser(null, EndUserConstants.EndUserLibraryType.SW);
  keyMedias = [];

  // euSign = this.euSignFile;

  //===============================================================================

  readFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onloadend = (evt: any) => {
        if (evt.target.readyState != FileReader.DONE) return;

        resolve({
          file: file,
          data: new Uint8Array(evt.target.result),
        });
      };
      reader.readAsArrayBuffer(file);
    });
  }

  //===============================================================================

  setKeyMedias(_keyMedias) {
    const keyMedias: any = _keyMedias;

    var kmSelect: any = document.getElementById('pkKeyMediaSelect');

    var length = kmSelect.options.length;
    for (i = length - 1; i >= 0; i--) {
      kmSelect.options[i] = null;
    }

    for (var i = 0; i < keyMedias.length; i++) {
      var opt = document.createElement('option');
      opt.appendChild(document.createTextNode(keyMedias[i].visibleName));
      opt.value = keyMedias[i].visibleName;
      kmSelect.appendChild(opt);
    }
  }

  //===============================================================================

  getSelectedKeyMedia() {
    var kmSelected = (<HTMLSelectElement>(
      document.getElementById('pkKeyMediaSelect')
    )).value;

    for (var i = 0; i < this.keyMedias.length; i++) {
      if (this.keyMedias[i].visibleName == kmSelected) return this.keyMedias[i];
    }

    return null;
  }

  //===============================================================================

  setLibraryType(type) {
    var pkFileBlock = document.getElementById('pkFileBlock');
    var pkKeyMediaBlock = document.getElementById('pkKeyMediaBlock');
    var signBlock = document.getElementById('signBlock');

    switch (type) {
      case EndUserConstants.EndUserLibraryType.JS:
        pkFileBlock.style.display = 'block';
        pkKeyMediaBlock.style.display = 'none';
        signBlock.style.display = 'none';
        this.euSign = this.euSignFile;
        break;
      case EndUserConstants.EndUserLibraryType.SW:
        pkFileBlock.style.display = 'none';
        pkKeyMediaBlock.style.display = 'block';
        signBlock.style.display = 'none';
        this.euSign = this.euSignKeyMedia;
        break;
    }

    this.initialize()
      .then(() => {
        if (this.euSign == this.euSignFile) return [];

        return this.euSign.GetKeyMedias();
      })
      .then((keyMedias) => {
        this.setKeyMedias(keyMedias);

        signBlock.style.display = 'block';
      })
      .catch((e) => {
        var msg = e.message || e;

        console.log('Initialize error: ' + msg);

        alert(
          'Виникла помилка при ініціалізації бібліотеки. ' +
            'Опис помилки: ' +
            msg
        );
      });
  }

  //===============================================================================

  // Ініціалізація бібліотеки
  initialize() {
    return new Promise((resolve, reject) => {
      if (this.euSign == this.euSignFile) {
        this.euSign
          .IsInitialized()
          .then((result) => {
            if (result) {
              console.log('EndUser: already initialized');
              resolve(null);
              return;
            }

            console.log('EndUser: initializing...');
            return this.euSign.Initialize(this.euSettings);
          })
          .then(() => {
            console.log('EndUser: initialized');

            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      } else {
        // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
        this.euSign
          .GetLibraryInfo()
          .then((result) => {
            if (!result.supported) {
              throw (
                'Бібліотека web-підпису не підтримується ' +
                'в вашому браузері або ОС'
              );
            }

            if (!result.loaded) {
              // Бібліотека встановлена, але потребує оновлення
              if (result.isNativeLibraryNeedUpdate) {
                throw (
                  'Бібліотека web-підпису потребує оновлення. ' +
                  'Будь ласка, встановіть оновлення за посиланням ' +
                  result.nativeLibraryInstallURL
                );
              }

              // Якщо браузер підтримує web-розширення рекомендується
              // додатково до нативних модулів встановлювати web-розширення
              // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
              if (
                result.isWebExtensionSupported &&
                !result.isWebExtensionInstalled
              ) {
                throw (
                  'Бібліотека web-підпису потребує встановлення web-розширення. ' +
                  'Будь ласка, встановіть web-розширення за посиланням ' +
                  result.webExtensionInstallURL +
                  ' та оновіть сторінку'
                );
              }

              // Бібліотека (нативні модулі) не встановлені
              throw (
                'Бібліотека web-підпису потребує встановлення. ' +
                'Будь ласка, встановіть бібліотеку за посиланням ' +
                result.nativeLibraryInstallURL +
                ' та оновіть сторінку'
              );
            }

            return this.euSign.IsInitialized();
          })
          .then((result) => {
            if (result) {
              console.log('EndUser: already initialized');
              resolve(null);
              return;
            }

            console.log('EndUser: initializing...');
            return this.euSign.Initialize(this.euSettings);
          })
          .then(() => {
            console.log('EndUser: initialized');
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  }

  //===============================================================================

  readPrivateKey() {
    var useFile = this.euSign == this.euSignFile;

    var pkFileInput: any = useFile ? document.getElementById('pkFile') : null;
    var passwordInput: any = document.getElementById(
      useFile ? 'pkFilePassword' : 'pkKeyMediaPassword'
    );
    var selectedKM: any = useFile ? null : this.getSelectedKeyMedia();
    var kmSelect: any = document.getElementById('pkKeyMediaSelect');
    /*
      Загальне ім'я ЦСК з списку CAs.json, який видав сертифікат для ос. ключа.
      Якщо null бібліотека намагається визначити ЦСК автоматично за 
      сервером CMP\сертифікатом. Встановлюється у випадках, коли ЦСК не 
      підтримує CMP, та для пришвидшення пошуку сертифіката ос. ключа
    */
    var caCN = null;
    /*
      Сертифікати, що відповідають ос. ключу (масив об'єктів типу Uint8Array). 
      Якщо null бібліотека намагається завантажити їх з ЦСК автоматично з сервера CMP.
      Встановлюється у випадках, коли ЦСК не підтримує CMP, та для пришвидшення 
      пошуку сертифіката ос. ключа
    */
    var pkCertificates = null;

    return new Promise((resolve, reject) => {
      if (useFile && (pkFileInput.value == null || pkFileInput.value == '')) {
        pkFileInput.focus();

        reject('Не обрано файл з ос. ключем');

        return;
      }

      if (!useFile && !selectedKM) {
        kmSelect.focus();

        reject('Не обрано носій з ос. ключем');

        return;
      }

      if (passwordInput.value == null || passwordInput.value == '') {
        passwordInput.focus();
        reject('Не вказано пароль до ос. ключа');

        return;
      }

      if (this.euSign == this.euSignFile) {
        this.readFile(pkFileInput.files[0])
          .then((result: any) => {
            console.log('Private key file readed');

            // Якщо файл з ос. ключем має розширення JKS, ключ може містити декілька ключів,
            // для зчитування такого ос. ключа необхіно обрати який ключ повинен зчитуватися
            if (result.file.name.endsWith('.jks')) {
              return this.euSign
                .GetJKSPrivateKeys(result.data)
                .then((jksKeys) => {
                  console.log('EndUser: jks keys got');

                  // Для спрощення прикладу обирається перший ключ
                  return this.euSign.ReadPrivateKeyBinary(
                    jksKeys[0].privateKey,
                    passwordInput.value,
                    pkCertificates,
                    caCN
                  );
                });
            }

            return this.euSign.ReadPrivateKeyBinary(
              result.data,
              passwordInput.value,
              pkCertificates,
              caCN
            );
          })
          .then((result) => {
            resolve(result);
          })
          .catch((e) => {
            reject(e);
          });
      } else {
        var keyMedia = new EndUserKeyMedia(selectedKM);
        keyMedia.password = passwordInput.value;

        this.euSign
          .ReadPrivateKey(keyMedia, pkCertificates, caCN)
          .then((result) => {
            resolve(result);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  }

  //===============================================================================

  signData() {
    var dataInput: any = document.getElementById('data-textarea');
    var signInput: any = document.getElementById('sign-textarea');

    this.readPrivateKey()
      .then((result: any) => {
        console.log('EndUser: private key readed ' + result.subjCN + '.');

        return this.euSign.SignDataInternal(true, dataInput.value, true);
      })
      .then((sign) => {
        console.log('EndUser: data signed');
        console.log('Data: ' + dataInput.value);
        console.log('Sign: ' + sign);

        signInput.value = sign;

        alert('Дані успішно підписані');
      })
      .catch((e) => {
        var msg = e.message || e;

        console.log('Sign data error: ' + msg);

        alert('Виникла помилка при підписі даних. ' + 'Опис помилки: ' + msg);
      });
  }

  //===============================================================================

  init() {
    document
      .getElementById('pkTypeFile')
      .addEventListener(
        'click',
        () => this.setLibraryType(EndUserConstants.EndUserLibraryType.JS),
        false
      );

    document
      .getElementById('pkTypeKeyMedia')
      .addEventListener(
        'click',
        () => this.setLibraryType(EndUserConstants.EndUserLibraryType.SW),
        false
      );

    document
      .getElementById('sign-button')
      .addEventListener('click', () => this.signData(), false);

    this.setLibraryType(EndUserConstants.EndUserLibraryType.JS);
  }
}
