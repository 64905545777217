import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { LanguageLoader } from './language-loader';

import { LanguagesService } from './languages.service';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { LanguageSwitcherComponent } from './components/language-switcher.component';
import { SwitchButtonComponent } from './components/switch-button.component';

@NgModule({
    imports: [
        CommonModule,

        MatMenuModule,
        MatIconModule,
        MatButtonModule,

        TranslateModule
    ],
    declarations: [
        LanguageSwitcherComponent,
        SwitchButtonComponent
    ],
    exports: [
        LanguageSwitcherComponent,
        SwitchButtonComponent,
        TranslateModule
    ]
})
export class LanguagesModule {
    static forRoot(): ModuleWithProviders<LanguagesModule> {
        return {
            ngModule: LanguagesModule,
            providers: [
                LanguagesService,
                TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: LanguageLoader } }).providers
            ]
        };
    }

    static forChild(): ModuleWithProviders<LanguagesModule> {
        return {
            ngModule: LanguagesModule,
            providers: [
                LanguagesService,
                TranslateModule.forChild({ loader: { provide: TranslateLoader, useClass: LanguageLoader } }).providers
            ]
        };
    }
}
