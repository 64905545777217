<h1 mat-dialog-title [innerHTML]="data.title"></h1>

<div mat-dialog-content class="mb-4">
  <div class="flex-container">
    <span
      *ngIf="icon"
      class="material-icons mr-4"
      style="font-size: 36px"
      [style.color]="icon.color"
    >
      {{ icon.icon }}
    </span>
    <p>{{ data.text }}</p>
  </div>
</div>

<div mat-dialog-actions>
  <ng-template ngFor let-button [ngForOf]="buttons" let-i="index">
    <button
      *ngIf="button.raised"
      mat-raised-button
      color="{{ button.color || 'primary' }}"
      (click)="buttonClick(i)"
      [class.mr-4]="i != buttons.length - 1"
    >
      {{ button.label }}
    </button>
    <button
      *ngIf="!button.raised"
      mat-button
      color="{{ button.color || 'primary' }}"
      (click)="buttonClick(i)"
    >
      {{ button.label }}
    </button>
    <div class="flex-spacer" *ngIf="i == 0"></div>
  </ng-template>
</div>
