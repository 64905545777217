import {
  NgModule,
  Injectable,
  ErrorHandler,
  Inject,
  InjectionToken,
  isDevMode,
} from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import * as Rollbar from 'rollbar';
import { Meta, Title } from '@angular/platform-browser';

import { LanguagesModule } from './modules/translate/languages.module';
// import { JwtInterceptorModule } from './interceptors/jwt.interceptor';
import { AuthModule } from './services/auth.service';

import { AuthGuard } from './services/auth.guard';
import { MonitoringService } from './services/logging.service';

const rollbarConfig = {
  accessToken: '5ef0da2217a44d8f8d69de9c9a1329eb',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private _monitoringService: MonitoringService
  ) {}

  handleError(error: any): void {
    if (!isDevMode()) {
      this.rollbar.error(error.originalError || error);
      this._monitoringService.logException(error);
    }

    if (
      error.rejection &&
      error.rejection.message &&
      error.rejection.message.startsWith('Loading chunk')
    ) {
      this.clearCacheStorage().then(() => {
        window.location.reload();
      });

      return;
    }

    console.error(error);
  }

  private clearCacheStorage(): Promise<Promise<boolean>[][]> {
    return caches.keys().then((cacheKeys) =>
      Promise.all(
        cacheKeys.map((cacheKey) => {
          const ngswRegex = /^(ngsw).*/;
          if (ngswRegex.test(cacheKey)) {
            return caches
              .open(cacheKey)
              .then((cache) =>
                cache
                  .keys()
                  .then((requests) => requests.map((req) => cache.delete(req)))
              );
          }
        })
      )
    );
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@NgModule({
  imports: [
    LanguagesModule.forRoot(),
    AuthModule.forRoot(),
    NgxPermissionsModule.forRoot(),
  ],

  providers: [
    AuthGuard,
    Meta,
    Title,
    MonitoringService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
})
export class AppCoreModule {}
