import { Injectable, EventEmitter } from '@angular/core';
import { ConfirmDialogComponent } from '../components/shared/confirm-dialog.component';
import { DialogComponent } from '../components/shared/info-dialog.component';

import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/Observer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class SharedService {
  onToggleSidenav: EventEmitter<null> = new EventEmitter<null>();
  translate: any = {};

  constructor(
    private _dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _translate: TranslateService
  ) {
    this._getTranslate();
    this._translate.onLangChange.subscribe(() => this._getTranslate());
  }

  openConfirmDialog(title: string, button: string): Observable<any> {
    return new Observable((observer: Observer<boolean>) => {
      const confirmDialog = this._dialog.open(ConfirmDialogComponent, {
        data: {
          title: title,
          button: button,
        },
        autoFocus: false,
        disableClose: true,
      });
      confirmDialog.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  private _getTranslate() {
    this._translate
      .get([
        'common.confirm',
        'common.cancel',
        'common.delete',
        'common.saved',
        'common.error',
        'common.close',
      ])
      .subscribe((res) => (this.translate = res));
  }

  infoDialog(
    title: string,
    text: string,
    buttons?: {
      label: string;
      raised?: boolean;
      icon?: string;
      color?: string;
    }[],
    level = 'error'
  ) {
    const dialogRef = this._dialog.open(DialogComponent, {
      data: {
        title: title,
        text: text,
        buttons: buttons,
        level: level,
      },
      width: '500px',
      autoFocus: false,
      hasBackdrop: true,
      disableClose: true,
    });

    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close(-1);
    });

    return dialogRef;
  }

  dialog(
    title: string,
    message: string,
    buttons?: {
      label: string;
      raised?: boolean;
      icon?: string;
      color?: string;
    }[],
    accept: Function = null,
    deny: Function = null,
    level = 'error'
  ) {
    return this._translate
      .get([title, message])
      .pipe(
        mergeMap((translate) => {
          return this._infoDialog(
            translate[title],
            translate[message],
            buttons,
            level
          )
            .afterClosed()
            .pipe(
              map((btnIndex) => {
                if (btnIndex === 1 && accept) {
                  accept();
                } else if (deny) {
                  deny();
                }
              })
            );
        })
      )
      .subscribe();
  }

  private _infoDialog(
    title: string,
    text: string,
    buttons?: {
      label: string;
      raised?: boolean;
      icon?: string;
      color?: string;
    }[],
    level = 'error'
  ) {
    const dialogRef = this._dialog.open(DialogComponent, {
      data: {
        title: title,
        text: text,
        buttons: buttons,
        level: level,
      },
      width: '500px',
      autoFocus: false,
      hasBackdrop: true,
      disableClose: true,
    });

    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close(-1);
    });

    return dialogRef;
  }

  toggleSidenav() {
    this.onToggleSidenav.emit();
  }

  savedMessage(duration = 2000, message = undefined) {
    this.snackBar.open(message || 'Збережено', null, {
      duration: duration,
      panelClass: ['mat-toolbar', 'mat-success'],
    });
  }

  errorMessage(duration = 3000, message = undefined) {
    this.snackBar.open(message || 'Помилка', null, {
      duration: duration,
      panelClass: ['mat-toolbar', 'mat-warn'],
    });
  }
}
