import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment';

@Injectable()
export class LanguagesService {
  defaultLeng = 'uk';

  constructor(public translate: TranslateService) {}

  setDefaultLand() {
    this.translate.addLangs(['ru', 'uk', 'en']);
    this.translate.setDefaultLang(this.defaultLeng);
    const leng = sessionStorage.getItem('_leng');

    const useLeng = leng || this.defaultLeng;
    this.translate.use(useLeng);

    moment.locale(useLeng);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(event.lang);
    });
  }

  changeLang(lang: string) {
    sessionStorage.setItem('_leng', lang);
    this.translate.use(lang);
  }

  getCurrentLanguageShortTitle(): string {
    return this.getLanguageShortTitle(this.translate.currentLang);
  }

  private getLanguageShortTitle(lang: string): string {
    switch (lang) {
      case 'uk':
        return 'УКР';
      case 'ru':
        return 'РУС';
      case 'en':
        return 'ENG';
      default:
        return '';
    }
  }
}
