import { FormControl, Validators } from '@angular/forms';

export default class DynamicFromGroupUtils {
    static keyValueToDynamicForm(object: any) {
        const keys = Object.keys(object),
            group: any = {};

        keys.forEach(key => {
            const validatirs = [];
            let value = object[key].value || '';

            if (object[key].required)
                validatirs.push(Validators.required);

            if (object[key].inputtype === 'phone') {
                validatirs.push(Validators.maxLength(9));
                validatirs.push(Validators.minLength(9));
                validatirs.push(Validators.pattern('[0-9]*'));
                if (value && value.length >= 9)
                    value = value.substring(value.length - 9);
            }

            if (object[key].inputtype === 'email')
                validatirs.push(Validators.email);

            group[key] = new FormControl(value, validatirs);
        });

        return { keys, group, source: object };
    }
}
