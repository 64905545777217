import { NgModule, Directive, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({ selector: '[cPointer]' })
export class CursorPointerDirective {
    constructor(el: ElementRef) {
        el.nativeElement.style.cursor = 'pointer';
    }
}

@Directive({ selector: '[focus]' })
export class FocusDirective implements OnInit {

    @Input('focus') isFocused: boolean;

    constructor(private hostElement: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        //if (this.isFocused)
        //    this.renderer.invokeElementMethod(this.hostElement.nativeElement, 'focus');
    }
}

import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [CursorPointerDirective, FocusDirective],
    exports: [CursorPointerDirective, FocusDirective]
})
export class StylesDirectivesModule { }
