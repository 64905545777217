import {
    Component,
    OnInit,
    OnDestroy,
    Inject,
    EventEmitter,
    Output,
  } from '@angular/core';
  import { FormBuilder, Validators } from '@angular/forms';
  import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
  import { JsAgentSigner } from 'src/modules/eu-sign/agent-signer.service';
  import providers from '../../assets/data/CAs.json';
  
  export class ReadKeyError {
    message: string;
    code: number;
  }
  
  @Component({
    selector: 'app-tax-private-key',
    templateUrl: 'private-key.component.html',
  })
  export class PrivateKeyComponent implements OnInit, OnDestroy {
    initializeLoading = false;
    keyReadLoading = false;
    keyProviders: any[];
    hide = true;
    onPrivateKeyDeleted = new EventEmitter();
    mode: 'read' | 'default' = 'default';
  
    privateKeyForm = this._fb.group({
      privateKeyFileName: this._fb.control('', Validators.required),
      privateKeyBase64: this._fb.control(''),
      provider: this._fb.control(null),
      keyPassword: this._fb.control('', Validators.required),
      caCN: this._fb.control(null),
    });
    get provider() {
      return this.privateKeyForm.get('provider').value;
    }
    error = null;
    fileName = null;
  
    get loading() {
      return this.initializeLoading || this.keyReadLoading;
    }
    endUserOwnerInfo: any;
    isConfirm = false;
    privateKeyModel: any;
    private _isSaved = false;
    @Output() onKeyRead = new EventEmitter();
    @Output() onKeyError = new EventEmitter();
    isModal = true;
  
    constructor(
      private _dialogRef: MatDialogRef<PrivateKeyComponent>,
      private _agentSigner: JsAgentSigner,
      @Inject(MAT_DIALOG_DATA)
      private data: {
        agentSigner: JsAgentSigner;
        isConfirm: boolean;
        mode: 'read' | 'default';
      },
      private _fb: FormBuilder
    ) {
      if (this._dialogRef && this.data) {
        this._agentSigner = this.data.agentSigner;
        this.isConfirm = this.data.isConfirm;
  
        if (this.data.mode) {
          this.mode = this.data.mode;
        }
      } else {
        this.isModal = false;
      }
  
      this.keyProviders = providers.map((p) => {
        if (p.issuerCNs && p.issuerCNs.length > 0) {
          (<any>p).name = p.issuerCNs[0];
        }
  
        return p;
      });
    }
  
    async ngOnInit() {
      debugger
      const isInitialized = await this._agentSigner.IsInitialized();
      if (!isInitialized) {
        await this._initialize();
      }
  
      if (this.mode == 'read') {
      } else {
        const isPrivateKeyReaded = await this._agentSigner.isPrivateKeyReaded();
        if (isPrivateKeyReaded) {
          var certificates = await this._agentSigner.GetOwnCertificates();
          if (certificates && certificates.length > 0) {
            const cert = certificates[0];
            this.endUserOwnerInfo = cert.infoEx;
          }
        } else {
          this.isConfirm = false;
  
          try {
            const pk = localStorage.getItem('pk');
            if (pk) {
              this._isSaved = true;
              const pkModel = JSON.parse(decodeURIComponent(window.atob(pk)));
              const provider = this._findProvider(pkModel.provider);
  
              this.privateKeyForm.patchValue({
                privateKeyFileName: pkModel.privateKeyFileName,
                privateKeyBase64: pkModel.privateKeyBase64,
                provider: provider,
                keyPassword: pkModel.password,
                caCN: pkModel.caCN,
              });
  
              this.isConfirm = true;
              await this.readPrivateKey();
            }
          } catch (err) {
            console.error(err);
            localStorage.removeItem('pk');
          }
        }
      }
    }
  
    private async _initialize() {
      this.initializeLoading = true;
      await this._agentSigner.initialize();
      this.initializeLoading = false;
    }
  
    confirmPrivateKey() {
      this.privateKeyModel = this.privateKeyForm.getRawValue();
      let caCN = this.privateKeyModel.caCN;
      if (this.provider) {
        caCN = this.provider.name;
        this.privateKeyModel.caCN = caCN;
        this.privateKeyModel.provider = this.provider;
      }
  
      const result = this._getResultData();
      if (!this.isModal) {
        this.onKeyRead.emit(result);
      } else {
        this._dialogRef.close(result);
      }
    }
  
    async readPrivateKey() {
      this.privateKeyModel = this.privateKeyForm.getRawValue();
      this.privateKeyModel.password = this.privateKeyModel.keyPassword;
  
      let caCN = this.privateKeyModel.caCN;
      if (this.provider) {
        caCN = this.provider.name;
        this.privateKeyModel.caCN = caCN;
        this.privateKeyModel.provider = this.provider;
      }
  
      this.endUserOwnerInfo = await this._readPrivateKey(
        this.privateKeyModel.privateKeyBase64,
        this.privateKeyModel.privateKeyFileName,
        this.privateKeyModel.password,
        caCN
      );
    }
  
    private async _readPrivateKey(
      privateKeyBase64: string,
      privateKeyFileName: string,
      password: string,
      caCN?: string
    ) {
      this.error = null;
      this.keyReadLoading = true;
      this.endUserOwnerInfo = null;
      this.privateKeyForm.disable();
      this._isSaved = false;
  
      const ownerInfo = await this._agentSigner
        .readPrivateKey(privateKeyBase64, privateKeyFileName, password, caCN)
        .catch((err: ReadKeyError) => {
          this.error = err.message;
          this.privateKeyForm.enable();
          this.keyReadLoading = false;
          return null;
        });
  
      if (!ownerInfo) {
        throw 'Read private key error';
      }
  
      var certificates = await this._agentSigner.GetOwnCertificates();
      ownerInfo.certInfo = certificates[0].infoEx;
      ownerInfo.subjectKeyId = ownerInfo.certInfo.publicKeyID.replaceAll(' ', '');
      this.keyReadLoading = false;
      this.privateKeyForm.enable();
      return ownerInfo;
    }
  
    async resetPrivateKey() {
      await this._agentSigner.resetPrivateKey();
      this.endUserOwnerInfo = null;
      this.privateKeyForm.reset();
      this.fileName = null;
      localStorage.removeItem('pk');
      this.onPrivateKeyDeleted.emit();
    }
  
    savePrivateKey() {
      try {
        const modelJson = JSON.stringify(this.privateKeyModel);
        const preEncoded = encodeURIComponent(modelJson);
        const encoded = window.btoa(preEncoded);
        localStorage.setItem('pk', encoded);
        this._isSaved = true;
      } catch (err) {
        console.error(err);
        localStorage.removeItem('pk');
        this.onKeyError.emit(err);
      }
  
      const result = this._getResultData();
      if (!this.isModal) {
        this.onKeyRead.emit(result);
      } else {
        this._dialogRef.close(result);
      }
    }
  
    private _getResultData() {
      if (this.mode == 'read') {
        return {
          ownerInfo: this.endUserOwnerInfo,
          keyData: {
            privateKeyBase64: this.privateKeyModel.privateKeyBase64,
            privateKeyFileName: this.privateKeyModel.privateKeyFileName,
            provider: this.privateKeyModel.provider,
          },
        };
      } else {
        return this.endUserOwnerInfo;
      }
    }
  
    async close() {
      if (!this._isSaved) {
        await this._agentSigner.resetPrivateKey();
      }
  
      this._dialogRef.close();
    }
  
    async onFileChange(evt) {
      const fileName = evt.target.files[0].name;
      const dataUrl = await this.toBase64(evt.target.files[0]);
      const fileBase64 = this._getBase64FromDataUrl(dataUrl);
      this.fileName = fileName;
  
      this.privateKeyForm.get('privateKeyFileName').patchValue(fileName);
      this.privateKeyForm.get('privateKeyBase64').patchValue(fileBase64);
    }
  
    toBase64 = (file: Blob): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.toString());
        reader.onerror = (error) => reject(error);
      });
  
    private _getBase64FromDataUrl(dataUrl: string) {
      const pats = dataUrl.split('base64,');
      if (pats.length < 2) {
        return null;
      }
  
      const base64 = pats[1];
      return base64;
    }
  
    private _findProvider(address: string) {
      return providers.find((p) => p.address == address);
    }
  
    ngOnDestroy() {}
  }
  