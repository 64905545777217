import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EndUserOwnerInfo } from 'euscp/EndUserOwnerInfo';
import { PrivateKeyComponent } from './private-key.component';
import { JsAgentSigner } from './agent-signer.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EuSignNewService {
  get isReaded() {
    return this._ownerInfo != null;
  }
  private _ownerInfo: EndUserOwnerInfo;

  constructor(
    private _agentSigner: JsAgentSigner,
    private _dialog: MatDialog
  ) {}

  async getOwnerInfo(showInfo = false): Promise<EndUserOwnerInfo> {
    if (this._ownerInfo && !showInfo) {
      return this._ownerInfo;
    }

    return this._readPrivateKey(showInfo);
  }

  async getPrivateKeyData(mode = 'default'): Promise<any> {
    const dialogRef = this._dialog.open(PrivateKeyComponent, {
      data: { agentSigner: this._agentSigner, mode: 'read' },
      width: '70%',
      autoFocus: false,
      disableClose: true,
    });

    return dialogRef.afterClosed().toPromise();
  }

  async checkPrivateKey(requiredOrganization: string): Promise<boolean> {
    if (!this._ownerInfo) {
      await this._readPrivateKey();
    }
    return (
      this._ownerInfo.subjDRFOCode == requiredOrganization ||
      this._ownerInfo.subjEDRPOUCode == requiredOrganization
    );
  }

  private _readPrivateKey(isConfirm = false): Promise<EndUserOwnerInfo> {
    const dialogRef = this._dialog.open(PrivateKeyComponent, {
      data: { agentSigner: this._agentSigner, isConfirm },
      width: '70%',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.componentInstance.onPrivateKeyDeleted.subscribe(
      () => (this._ownerInfo = null)
    );

    return dialogRef
      .afterClosed()
      .pipe(
        map((ownerInfo) => {
          if (ownerInfo) {
            this._ownerInfo = ownerInfo;
          }

          return ownerInfo;
        })
      )
      .toPromise();
  }

  async sign(data: string) {
    const isInitialized = await this._agentSigner.IsInitialized();
    if (!isInitialized) {
      const isPkConfirmed = await this._readPrivateKey();
      if (!isPkConfirmed) {
        return;
      }
    }

    const isPrivateKeyReaded = await this._agentSigner.isPrivateKeyReaded();
    if (!isPrivateKeyReaded) {
      const result = await this.readPrivateKey();
      if (!result) {
        return;
      }
    }

    return this._agentSigner.signData(data);
  }

  async envelopData(data: string) {
    return this._agentSigner.envelopData(data);
  }

  async isPrivateKeyReaded() {
    return this._agentSigner.isPrivateKeyReaded();
  }

  async readPrivateKey(): Promise<boolean> {
    const dialogRef = this._dialog.open(PrivateKeyComponent, {
      data: { agentSigner: this._agentSigner },
    });

    const result = await dialogRef.afterClosed().toPromise();
    if (!result) {
      return null;
    }

    return result;
  }

  //   async readPrivateKey(
  //     privateKeyBase64: string,
  //     privateKeyFileName: string,
  //     password: string
  //   ) {
  //     return this._agentSigner.readPrivateKey(
  //       privateKeyBase64,
  //       privateKeyFileName,
  //       password
  //     );
  //   }

  initialize() {
    return this._agentSigner.initialize();
  }

  async companyInfo(): Promise<any> {
    if (!this._ownerInfo) {
      await this._readPrivateKey();
    }

    let companyType = '';
    let companyName = '';
    if (this._ownerInfo.subjOrg == 'ФІЗИЧНА ОСОБА') {
      companyType = 'ФІЗИЧНА ОСОБА';
      companyName = this._ownerInfo.subjCN;
    } else {
      const companyNameParts = this._ownerInfo.subjOrg.split(' ');
      if (companyNameParts.length > 0) {
        companyType = companyNameParts[0];
        companyName = this._ownerInfo.subjOrg.replace(companyType, '');
      } else {
        companyName = this._ownerInfo.subjOrg;
      }
    }

    return {
      subjectName: companyName,
      subjectType: companyType,
      director: this._ownerInfo.subjFullName,
      subjectCode:
        this._ownerInfo.subjEDRPOUCode || this._ownerInfo.subjDRFOCode,
      isOrganization:
        this._ownerInfo.subjOrg !== 'ФІЗИЧНА ОСОБА' &&
        this._ownerInfo.subjEDRPOUCode !== '',
    };
  }

  // openSignDialog(): ComponentRef<EUSignNewComponent> {
  //   let config = new OverlayConfig();

  //   config.height = 'auto';
  //   config.width = 'auto';
  //   config.hasBackdrop = false;

  //   config.positionStrategy = this.overlay
  //     .position()
  //     .global()
  //     .centerHorizontally()
  //     .top(`20px`);

  //   const filePreviewPortal = new ComponentPortal(EUSignNewComponent);
  //   const fusilliOverlayRef = this.overlay.create(config);
  //   const compRef =
  //     fusilliOverlayRef.attach<EUSignNewComponent>(filePreviewPortal);

  //   return compRef;
  // }
}
