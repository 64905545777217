import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppModuleShared } from './app.shared.module';
import { AppCoreModule } from './app.core.module';
import { AppComponent } from './components/app/app.component';
import { AuthService } from './services/auth.service';
import { LanguagesService } from './modules/translate/languages.service';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptorModule } from './interceptors/jwt.interceptor';
import '@angular/common/locales/global/uk';

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppCoreModule,
    AppModuleShared,
    BrowserAnimationsModule,
    JwtInterceptorModule,
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: 'SIGNALR_CONNECTION', useValue: environment.signal_r_endpoint },
    { provide: 'API_ENDPOINT', useValue: environment.api_endpoint },
    { provide: 'APP_NAME', useValue: environment.app_name },
    { provide: 'DIRECTORY_ENDPOINT', useValue: environment.directory_endpoint },
    //{
    //    provide: APP_INITIALIZER,
    //    useFactory: (as: AuthService, ps: NgxPermissionsService) => function () {
    //        return as.currentUserRoles().then((data) => {
    //            return ps.loadPermissions(data);
    //        });
    //    },
    //    deps: [AuthService, NgxPermissionsService],
    //    multi: true
    //}
    {
      provide: APP_INITIALIZER,
      useFactory: (as: AuthService) =>
        function () {
          return as.initUser();
        },
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ls: LanguagesService) =>
        function () {
          return ls.setDefaultLand();
        },
      deps: [LanguagesService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'uk' },
  ],
})
export class AppModule {}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
