<div class="language-switcher">
  <button mat-mini-fab color="default" [matMenuTriggerFor]="switcher">
    {{ currentLangTitle }}
  </button>
</div>

<mat-menu #switcher="matMenu">
  <button
    mat-menu-item
    (click)="changeLang('uk')"
    [disabled]="currentLang == 'uk'"
  >
    УКР
  </button>
  <button
    mat-menu-item
    (click)="changeLang('ru')"
    [disabled]="currentLang == 'ru'"
  >
    РУС
  </button>
  <button
    mat-menu-item
    (click)="changeLang('en')"
    [disabled]="currentLang == 'en'"
  >
    ENG
  </button>
</mat-menu>

<style>
  .language-switcher {
    display: inline-block;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
</style>
