import { Component, OnInit, OnDestroy } from '@angular/core';
import { SearchService } from '../search.service';

@Component({
    selector: 'app-search',
    templateUrl: 'search.component.html'
})
export class SearchComponent implements OnInit, OnDestroy {

    placeholder = '';
    isSearchActive = false;

    constructor(private _ss: SearchService) { }

    ngOnInit() {
        this._ss.initSearchSubject.subscribe(res => {
            this.isSearchActive = res.isActive;
            this.placeholder = res.placeholder;
        });
    }

    onChange = (value: string) => this._ss.onSearch(value);

    ngOnDestroy() {
        this._ss.initSearchSubject.complete();
        //this.sub.unsubscribe();
    }
}
