<h1 mat-dialog-title class="flex-container" *ngIf="isModal">
  <span>Зчитування ключа ЕЦП</span>
  <span class="flex-spacer"></span>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</h1>

<div mat-dialog-content>
  <div *ngIf="initializeLoading" class="text-center" style="padding: 30px 0">
    <h2>Завантаження бібліотеки підпису...</h2>
  </div>
  <div *ngIf="!initializeLoading">
    <div *ngIf="!endUserOwnerInfo">
      <div [formGroup]="privateKeyForm" utocomplete="off">
        <div class="row mb-3">
          <div class="col">
            <label>Кваліфікований надавач ел. довірчих послуг</label>
            <mat-select
              class="w-100 form-control"
              formControlName="provider"
              placeholder="Визначити автоматично"
              floatlLabel="never"
            >
              <mat-select-trigger>
                <span>
                  {{ provider ? provider.issuerCNs[0] : '' }}
                </span>
              </mat-select-trigger>
              <mat-option [value]=""> Визначити автоматично </mat-option>
              <mat-option *ngFor="let p of keyProviders" [value]="p">
                {{ p.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label>Файл ключа ЕЦП</label>

            <div class="input-group">
              <div class="custom-file">
                <input
                  id="inputGroupFile01"
                  type="file"
                  (change)="onFileChange($event)"
                  class="custom-file-input"
                  aria-describedby="inputGroupFileAddon01"
                />

                <label class="custom-file-label" for="inputGroupFile01">{{
                  fileName ? fileName : 'Виберіть файл'
                }}</label>
              </div>
            </div>
            <small>Допустимі формати: *.dat, *.pfx, *.pk8, *.zs2, *.jks</small>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label>Пароль захисту ключа</label>
            <div class="input-group">
              <input
                [type]="hide ? 'password' : 'text'"
                class="form-control"
                id="pkPassword"
                formControlName="keyPassword"
                autocomplete="new-password"
                placeholder="Пароль ключа ЕЦП"
                aria-label="Пароль ключа ЕЦП"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="hide = !hide"
                  style="padding: 4px 10px 0 10px"
                >
                  <span class="material-icons">{{
                    hide ? 'visibility' : 'visibility_off'
                  }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="endUserOwnerInfo">
      <table class="w-100">
        <tr *ngIf="endUserOwnerInfo.subjTitle">
          <td>Назва</td>
          <td>
            {{ endUserOwnerInfo.subjTitle }}
          </td>
        </tr>
        <tr *ngIf="endUserOwnerInfo.subjDRFOCode">
          <td>ІПН</td>
          <td>
            {{ endUserOwnerInfo.subjDRFOCode }}
          </td>
        </tr>
        <tr>
          <td>Власник</td>
          <td>
            <strong>
              {{ endUserOwnerInfo.subjCN }}
            </strong>
          </td>
        </tr>
        <tr>
          <td>АЦСК</td>
          <td>
            <strong> {{ endUserOwnerInfo.issuerCN }}</strong>
          </td>
        </tr>
        <tr *ngIf="endUserOwnerInfo.subjEDRPOUCode">
          <td>ЕДРПОУ код</td>
          <td>
            <strong>{{ endUserOwnerInfo.subjEDRPOUCode }}</strong>
          </td>
        </tr>
        <tr>
          <td>Серійний номер</td>
          <td>
            <strong>{{ endUserOwnerInfo.serial }}</strong>
          </td>
        </tr>
        <tr>
          <td>Ідентифікатор ключа</td>
          <td>
            <strong>{{ endUserOwnerInfo.subjectKeyId }}</strong>
          </td>
        </tr>
        <tr *ngIf="endUserOwnerInfo.certInfo">
          <td>Дійсний до</td>
          <td>
            <strong>{{
              endUserOwnerInfo.certInfo.certEndTime
                | date : 'dd MMMM yyyy HH:mm:ss'
            }}</strong>
          </td>
        </tr>
      </table>

      <!-- {{ endUserOwnerInfo | json }} -->
    </div>
    <div class="row mb-3 text-danger" *ngIf="error">
      <div class="col">{{ error }}</div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="close()">Закрити</button>
  <span class="flex-spacer"></span>
  <span class="bg-loader mr-3" *ngIf="loading"></span>
  <div *ngIf="endUserOwnerInfo">
    <button
      mat-raised-button
      (click)="resetPrivateKey()"
      class="mr-3"
      *ngIf="mode == 'default'"
    >
      Видалити
    </button>
    <button
      mat-raised-button
      (click)="savePrivateKey()"
      color="success"
      *ngIf="mode == 'default'"
    >
      {{ isConfirm ? 'Підтвердити' : ' Зберегти ключ' }}
    </button>
    <button
      mat-raised-button
      (click)="confirmPrivateKey()"
      color="success"
      *ngIf="mode == 'read'"
    >
      Підтвердити
    </button>
  </div>
  <button
    *ngIf="!endUserOwnerInfo"
    mat-raised-button
    [disabled]="!privateKeyForm.valid"
    (click)="readPrivateKey()"
    color="success"
  >
    Зчитати ключ
  </button>
</div>
