import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { SearchComponent } from './components/search.component';
import { SearchService } from './search.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        MatIconModule,
        MatButtonModule
    ],
    exports: [SearchComponent],
    declarations: [SearchComponent]
})
export class SearchModule {
    static forRoot(): ModuleWithProviders<SearchModule> {
        return {
            ngModule: SearchModule,
            providers: [SearchService]
        };
    }
}
