import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class SearchService {

    initSearchSubject = new Subject<any>();
    search = new EventEmitter<string>();

    constructor() {
    }

    onSearch = (value: string) => this.search.emit(value);

    init(placeholder: string) {
        this.initSearchSubject.next({ placeholder, isActive: true });
        return this.search;
    }

    destroy() {
        this.initSearchSubject.next({ placeholder: '', isActive: false });
    }
}
