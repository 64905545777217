import {
  NgModule,
  Injectable,
  Inject,
  EventEmitter,
  ModuleWithProviders,
  Injector,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { SharedService } from '../services/shared.service';
import { AuthTokenService } from '../services/auth-token.service';
import { RoutesConfigurationService } from '../services/routes-configure.service';
import { Router } from '@angular/router';
import { LanguagesService } from '../modules/translate/languages.service';

interface UserInfo {
  app_path: string;
  multi_company: boolean;
  session_id: string;
}

@Injectable()
export class AuthService {
  private readonly userInfoKey = 'userInfo';

  onUserInfo: EventEmitter<any> = new EventEmitter<any>();
  isMultiCompanies = false;

  constructor(
    private http: HttpClient,
    @Inject('API_ENDPOINT') private apiEndpoint: string,
    private ps: NgxPermissionsService,
    private sharedService: SharedService,
    private authTokenService: AuthTokenService,
    private routesService: RoutesConfigurationService,
    private _injector: Injector,
    private _languagesService: LanguagesService
  ) {}

  login(username: string, password: string, recapture?: string) {
    return this.authTokenService
      .requestToken(username, password, recapture)
      .map((ticket) => {
        this.initUser();
      });
  }

  impersonatedLogin(companyId: string, userId: string) {
    return this.authTokenService
      .startImpersonatedSession(companyId, userId)
      .subscribe((sessionId) => this.initUser());
  }

  impersonatedLogout() {
    return this.authTokenService
      .endImpersonatedSession()
      .subscribe(() => this.initUser());
  }

  initUser() {
    if (!this.authTokenService.hasTicket()) {
      this.webLogout(false);
      this.routesService.removeAppRoute();
      this.routesService.navigateToCurrentLocation();
    } else {
      this.refreshApp();
    }
  }

  selectCompany(userSessionId: string) {
    this.authTokenService.webSessionId = userSessionId;
  }

  refreshApp() {
    if (!this.authTokenService.webSessionId) {
      this.getDefaultUserSession().subscribe(
        (sessionId) => {
          if (sessionId) {
            this.authTokenService.webSessionId = sessionId;
            this._getUserInfo();
          } else {
            this.routesService.loadAppsModule();
          }
        },
        (error) => {
          if (error.status !== 401) {
            // this.webLogout();
          }
        }
      );
    } else {
      this._getUserInfo();
    }
  }

  private _getUserInfo() {
    this.getUserInfo(this.authTokenService.getTicket().username).subscribe(
      (info) => {
        if (info == null) {
          this._unauthenticationDialog();
          return;
        }

        this.ps.flushPermissions();
        this.ps.loadPermissions(info.roles);
        if (info.multi_company) {
          this.ps.addPermission('MULTI_APPS');
        }

        this.isMultiCompanies = info.multi_company;

        if (info.language_code) {
          this._languagesService.changeLang(info.language_code);
        } else {
          this._languagesService.setDefaultLand();
        }

        this.routesService.configureModuleRoute(
          info.application_id,
          info.app_path
        );
      },
      (error) => {
        if (error.status === 403) {
          this.webLogout();
        }

        if (error.status !== 401 || error.status !== 403) {
          // this.webLogout();
        }
      }
    );
  }

  connectionErrorDialog() {
    const buttons: any[] = [
      {
        label: 'Выход',
      },
      {
        label: 'Обновить',
      },
    ];

    this.sharedService
      .infoDialog(
        'Ошибка соединения',
        'Проверьте подключение к интернету!',
        buttons,
        'warning'
      )
      .afterClosed()
      .subscribe((btnIndex) => {
        if (btnIndex === 0 || btnIndex === -1) {
          this.webLogout(true);
        } else {
          window.location.reload();
        }
      });
  }

  private _userInfoErrorDialog() {
    const buttons: any[] = [
      {
        label: 'Выход',
      },
      {
        label: 'Обновить',
      },
    ];

    this.sharedService
      .infoDialog(
        'Ошибка загрузки данных сотрудника',
        'Если ваша проблема не решиться в течении 5 минут, обратитесь в службу поддержки!',
        buttons
      )
      .afterClosed()
      .subscribe((btnIndex) => {
        if (btnIndex === 0 || btnIndex === -1) {
          this.webLogout(true);
        } else {
          window.location.reload();
        }
      });
  }

  private _unauthenticationDialog() {
    const buttons: any[] = [
      {
        label: 'Выход',
      },
    ];

    this.sharedService
      .infoDialog(
        'Ошибка входа в сиситему',
        'Пользователь не найден. Обратитесь к администратору.',
        buttons
      )
      .afterClosed()
      .subscribe(() => this.webLogout());
  }

  private getDefaultUserSession() {
    return this.http.get<any>(
      `${this.apiEndpoint}/app-client/company-user-session`
    );
  }

  getUserInfo(email?: string): Observable<any> {
    const e = email || this.currentUserEmail();
    return this.http
      .post<any>(`${this.apiEndpoint}/account/user-info`, { email: e })
      .pipe(
        map((res) => {
          localStorage.setItem(this.userInfoKey, JSON.stringify(res));
          this.onUserInfo.emit(res);
          return res;
        }),
        catchError((error) => {
          if (error.status === 403 || error.status === 401) {
            throw error;
          }

          const userInfo = localStorage.getItem(this.userInfoKey);
          return of(JSON.parse(userInfo));
        })
      );
  }

  get userInfo() {
    return localStorage.getItem(this.userInfoKey)
      ? JSON.parse(localStorage.getItem(this.userInfoKey))
      : null;
  }

  get userInfoSubscription(): Observable<any> {
    return new Observable((observer) => {
      if (this.userInfo) {
        observer.next(this.userInfo);
      } else {
        this.onUserInfo.subscribe((userInfo: any) => {
          observer.next(userInfo);
        });
      }
    });
  }

  logout() {
    if (!this.currentUser) {
      return;
    }

    return this.http
      .post<any>(`${this.apiEndpoint}/account/logout`, {})
      .map(() => {
        this.routesService.removeAppRoute();
        this.webLogout();
      });
  }

  webLogout(redirectToLoginPage = true) {
    this.ps.flushPermissions();
    this.authTokenService.removeTicket();
    localStorage.removeItem(this.userInfoKey);

    const router = this._injector.get(Router);
    const location = this._injector.get(Location);

    if (redirectToLoginPage) {
      router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        router.navigate(['/login'], {
          queryParams: { returnUrl: location.path() },
        })
      );
    }
  }

  companyLogout() {
    this.ps.flushPermissions();
    this.authTokenService.removeCompanyTokens();

    const buttons: any[] = [
      {
        label: 'Выход',
      },
    ];

    if (this.isMultiCompanies) {
      buttons.push({
        label: 'Мої компанії',
        raised: true,
      });
    }

    this.sharedService
      .infoDialog(
        'Помилка доступа',
        'У вас немає доступа до обраної компании або вийшов термін логіну.',
        buttons
      )
      .afterClosed()
      .subscribe((btnIndex) => {
        if (btnIndex === 0 || btnIndex === -1) {
          this.webLogout(true);
        } else {
          this.routesService.loadAppsModule();
        }
      });
  }

  get isLoggedIn() {
    return this.authTokenService.hasTicket();
  }

  currentUserEmail(): any {
    return this.currentUser.username;
  }

  private get currentUser(): any {
    return this.authTokenService.getTicket();
  }

  register(email: string, password: string, confirmPassword: string) {
    return this.http
      .post<any>(`${this.apiEndpoint}/account/register`, {
        email,
        password,
        confirmPassword,
      })
      .map((user) => {
        // if (user && user.token)
        //    localStorage.setItem('currentUser', JSON.stringify(user));
        // return user;
      });
  }
}

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { DialogComponent } from '../components/shared/info-dialog.component';
import { catchError, map } from 'rxjs/operators';

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule],

  entryComponents: [DialogComponent],

  declarations: [DialogComponent],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthTokenService,
        SharedService,
        RoutesConfigurationService,
      ],
    };
  }
}
