import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';

import { EUSignComponent } from './eu-sign.component';
import { EUSignService } from './eu-sign.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { EuSignNewService } from './eu-sign-new.service';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    OverlayModule,
  ],

  declarations: [EUSignComponent],
})
export class EUSignModule {
  static forRoot(): ModuleWithProviders<EUSignModule> {
    return {
      ngModule: EUSignModule,
      providers: [EUSignService, EuSignNewService],
    };
  }
}
