import { Component } from '@angular/core';
import { LanguagesService } from '../languages.service';

@Component({
    selector: 'language-switcher',
    templateUrl: 'language-switcher.component.html'
})
export class LanguageSwitcherComponent {

    defaultLeng = 'uk';
    currentLang = this.defaultLeng;

    constructor(public ls: LanguagesService) {
        this.currentLang = ls.translate.currentLang;
    }

    changeLang(lang: string) {
        this.ls.changeLang(lang);
    }
}
