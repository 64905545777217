import { NgModule, Injectable, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { RouterModule, Router, Route, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'B072E36E-CF94-4885-B51A-418CFEE2C4D8',
    loadChildren: () =>
      import('../../modules/user-apps/user-apps.module').then(
        (m) => m.UserAppsModule
      ),
  },
  // tslint:disable-next-line: max-line-length
  //  { path: 'CC760E31-A025-4ECA-BA77-9B2C4DDB52B9', loadChildren:() => import('../modules/not-found/not-found.module').then(m => m.NotFoundModule) '../../medical-app/app/medical.module#MedicalModule' },
  // tslint:disable-next-line: max-line-length
  //  { path: '70AE492B-6752-4516-903E-DB931C0EE6F3', loadChildren:() => import('../modules/not-found/not-found.module').then(m => m.NotFoundModule) '../../logistics-app/logistics.module#LogisticsModule' },
  // tslint:disable-next-line: max-line-length
  //  { path: '1B5AF970-B5D0-4415-9025-DDE6A4845D3D', loadChildren:() => import('../modules/not-found/not-found.module').then(m => m.NotFoundModule) '../../sales-app/sales.module#SalesModule' },
  {
    path: '75C00ABE-B031-4DC2-ACE3-14F87DBAD4F6',
    loadChildren: () =>
      import('../../pos-app/pos.module').then((m) => m.PosModule),
  },
  {
    path: 'CB50A512-8470-4FFB-A6E7-36C28D5D6A26',
    loadChildren: () =>
      import('../../app-admin-tool/admin-tool.module').then(
        (m) => m.AdminToolModule
      ),
  },
  {
    path: '650411D3-779C-4D34-AE58-8B07B4AD1846',
    loadChildren: () =>
      import('../modules/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
  // tslint:disable-next-line: max-line-length
  // { path: '79F84439-37D6-4839-B68E-8BF334B91EFD', loadChildren:() => import('../modules/not-found/not-found.module').then(m => m.NotFoundModule) '../../app-chat/app-chat.module#ChatModule' },
  {
    path: '53B03750-C545-4026-A29C-4055B1509F80',
    loadChildren: () =>
      import('../../app-crm/app-crm.module').then((m) => m.CrmModule),
  },
];

@Injectable()
export class RoutesConfigurationService {
  private selectAppRoutePath = 'B072E36E-CF94-4885-B51A-418CFEE2C4D8';
  private medicalRoutePath = 'CC760E31-A025-4ECA-BA77-9B2C4DDB52B9';
  private logisticsRoutePath = '70AE492B-6752-4516-903E-DB931C0EE6F3';
  private salesRoutePath = '1B5AF970-B5D0-4415-9025-DDE6A4845D3D';
  private posRoutePath = '75C00ABE-B031-4DC2-ACE3-14F87DBAD4F6';
  private adminToolPath = 'CB50A512-8470-4FFB-A6E7-36C28D5D6A26';
  private chatPath = '79F84439-37D6-4839-B68E-8BF334B91EFD';
  private crmPath = '53B03750-C545-4026-A29C-4055B1509F80';

  private notFoundPath = '650411D3-779C-4D34-AE58-8B07B4AD1846';

  constructor(private location: Location, private injector: Injector) {}

  configureModuleRoute(application_id: string, modulePath: string) {
    const modulePathParts = modulePath.split('#');
    if (application_id) {
      this.loadRoute(this.getRouteByPath(application_id));
    } else {
      switch (modulePathParts[1]) {
        case 'MedicalModule':
          this.loadRoute(this.getRouteByPath(this.medicalRoutePath));
          break;

        case 'LogisticsModule':
          this.loadRoute(this.getRouteByPath(this.logisticsRoutePath));
          break;

        case 'SalesModule':
          this.loadRoute(this.getRouteByPath(this.salesRoutePath));
          break;

        case 'PosModule':
          this.loadRoute(this.getRouteByPath(this.posRoutePath));
          break;

        case 'AdminToolModule':
          this.loadRoute(this.getRouteByPath(this.adminToolPath));
          break;

        case 'ChatModule':
          this.loadRoute(this.getRouteByPath(this.chatPath));
          break;

        case 'CrmModule':
          this.loadRoute(this.getRouteByPath(this.crmPath));
          break;

        default:
          this.loadRoute(this.getRouteByPath(this.selectAppRoutePath));
          break;
      }
    }
  }

  loadAppsModule() {
    this.loadRoute(this.getRouteByPath(this.selectAppRoutePath));
  }

  removeAppRoute() {
    const router = this.injector.get(Router);
    const config = router.config;

    this.cleanDefaultRoute(config);
    this.removeLoadingRoute(config);
    this.replaceNotFoundRoute(config);
    router.resetConfig(config);
  }

  private loadRoute(route: Route) {
    const router = this.injector.get(Router);
    const config = router.config;

    this.removeLoadingRoute(config);

    // remove root route
    let rootIndex = config.map((r) => r.path).indexOf('root');
    if (rootIndex !== -1) {
      config[rootIndex].path = '';
    }

    if (rootIndex === -1) {
      rootIndex = config.map((r) => r.path).indexOf('');
    }

    // remove previouse module
    this.cleanDefaultRoute(config);
    this.replaceNotFoundRoute(config);

    config[rootIndex].children.push(route);
    router.resetConfig(config);

    this.navigateToCurrentLocation();
  }

  navigateToCurrentLocation() {
    const router = this.injector.get(Router);
    const path = this.location.path().split(/[?#]/)[0] || '/';

    router
      .navigate([path], { state: { data: { systemNavigate: true } } })
      .catch((e) => router.navigate(['/']));
  }

  private removeLoadingRoute(config: Route[]) {
    const loadingRouteIndex = config.map((r) => r.path).indexOf('');
    if (loadingRouteIndex === 1) {
      config.splice(loadingRouteIndex, 1);
    }
  }

  private cleanDefaultRoute(config: Route[]) {
    let appRouteIndex = config.map((r) => r.path).indexOf('root');
    if (appRouteIndex === -1) {
      appRouteIndex = config.map((r) => r.path).indexOf('');
    }

    if (appRouteIndex === -1) {
      return;
    }

    const defaultRouteIndex = config[appRouteIndex].children
      .map((r) => r.path)
      .indexOf('');
    if (defaultRouteIndex !== -1) {
      config[appRouteIndex].children.splice(defaultRouteIndex, 1);
    }
  }

  private getRouteByPath(path: string): Route {
    const route = routes.find(
      (r) => r.path.toLowerCase() === path.toLowerCase()
    );
    if (!route) {
      return null;
    }

    return {
      path: '',
      loadChildren: route.loadChildren,
      data: route.data,
    };
  }

  private replaceNotFoundRoute(config: Route[]) {
    const notFoundRouteIndex = config.map((r) => r.path).indexOf('**');
    if (notFoundRouteIndex !== -1) {
      config.splice(notFoundRouteIndex, 1);
    }

    const notFoundRoute = this.getRouteByPath(this.notFoundPath);
    notFoundRoute.path = '**';
    config.push(notFoundRoute);
  }
}

import { CommonModule } from '@angular/common';
@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
})
export class RoutesConfigurationModule {}
