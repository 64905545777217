import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { RootLayoutComponent } from './components/_layout/root-layout.component';

const routes: Routes = [
  {
    path: 'root',
    component: RootLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [NgxPermissionsGuard, AuthGuard],
    children: [
      // {
      //    path: 'admin',
      //    loadChildren: '../admin-app/admin.module#AdminModule',
      //    data: {
      //        client: 'AdminModule',
      //        permissions: {
      //            only: 'ADMIN',
      //            redirectTo: 'dashboard'
      //        }
      //    }
      // },
      //   {
      //     path: 'map',
      //     loadChildren: '../modules/map/map.module#MapModule',
      //     data: {
      //       permissions: {
      //         only: 'ADMIN',
      //         // redirectTo: 'dashboard'
      //       },
      //     },
      //   },
      {
        path: 'account',
        loadChildren: () =>
          import('../modules/account/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('../modules/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        data: {
          permissions: {
            only: ['CanSeeCompanyInfo'],
            redirectTo: 'dashboard',
          },
        },
      },
    ],
  },
  // this route should be at position 1
  //   {
  //     path: '',
  //     loadChildren: './modules/app-loading/app-loading.module#AppLoadingModule',
  //   },
  {
    path: 'login',
    loadChildren: () =>
      import('../modules/account/account').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('../modules/register-company/register-company.module').then(
        (m) => m.RegisterCompanyModule
      ),
  },

  // { path: 'phone-login', loadChildren: '../modules/phone-login/phone-login.module#PhoneLoginModule' },
  // { path: 'register', loadChildren: './components/register/register.module#RegisterModule' },
  // { path: 'reset-password', loadChildren: '../modules/reset-password/reset-password.module#ResetPasswordModule' },
  //   {
  //     path: 'account/change-email',
  //     loadChildren: '../modules/account/change-email.module#ChangeEmailModule',
  //   },
  //   {
  //     path: 'account/reset-password',
  //     loadChildren:
  //       '../modules/account/reset-password.module#ResetPasswordModule',
  //   },
  {
    path: 'account/forgot-password',
    loadChildren:
      () => import('../modules/account/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/app-loading/app-loading.module').then(
        (m) => m.AppLoadingModule
      ),
  },
];

export const AppRoutes = RouterModule.forRoot(routes);

export const routingComponents = [RootLayoutComponent];
