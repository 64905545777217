import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { EUSignComponent } from './eu-sign.component';

@Injectable()
export class EUSignService {
  private euSignSub: Observable<any>;
  private ueDialogRef: EUSignComponent;
  fusilliOverlayRef: OverlayRef;
  companyId = '';
  get hasSign(): boolean {
    return this.ueDialogRef != null;
  }

  private _isHidden = true;
  get isHidden(): boolean {
    return this._isHidden;
  }

  constructor(private overlay: Overlay, private _authService: AuthService) {
    this.companyId = this._authService.userInfo.company_id;
    this._authService.onUserInfo.subscribe((user: any) => {
      if (this.companyId !== user.company_id && this.fusilliOverlayRef) {
        this.fusilliOverlayRef.detach();
        this.ueDialogRef = null;
      }

      this.companyId = user.company_id;
    });

    this.euSignSub = new Observable((observer) => {
      if (this.ueDialogRef) {
        observer.next(this.ueDialogRef);
      } else {
        const compRef: ComponentRef<EUSignComponent> = this.openSignDialog();
        this.ueDialogRef = compRef.instance;
        compRef.instance.onPrivateKeyVerified.subscribe(() => {
          observer.next(this.ueDialogRef);
        });

        // const dialogRef = this._getEUSignDialog();
        // this.ueDialogRef = dialogRef.componentInstance;

        // this.ueDialogRef.onPrivateKeyVerified.subscribe(() => {
        //   observer.next(this.ueDialogRef);
        // });
      }
    });
  }

  openSignDialog(): ComponentRef<EUSignComponent> {
    if (this.fusilliOverlayRef) {
      this.ueDialogRef.show();
      this.overlay.position().global().centerHorizontally().top(`20px`);
      this.fusilliOverlayRef.updateSize({ width: 'auto', height: 'auto' });
    } else {
      let config = new OverlayConfig();

      config.height = 'auto';
      config.width = 'auto';
      config.hasBackdrop = false;

      config.positionStrategy = this.overlay
        .position()
        .global()
        .centerHorizontally()
        .top(`20px`);

      const filePreviewPortal = new ComponentPortal(EUSignComponent);
      this.fusilliOverlayRef = this.overlay.create(config);
      const compRef =
        this.fusilliOverlayRef.attach<EUSignComponent>(filePreviewPortal);

      this.ueDialogRef = compRef.instance;

      compRef.instance.onClose.subscribe(() => {
        this.closeSignDialog();
      });

      compRef.instance.onSignBroken.subscribe(() => {
        this.closeSignDialog();
        this.fusilliOverlayRef.detach();
        this.fusilliOverlayRef = null;
        this.ueDialogRef = null;
      });

      compRef.instance.onSignDelete.subscribe(() => {
        this.closeSignDialog();
        this.fusilliOverlayRef.detach();
        this.fusilliOverlayRef = null;
        this.ueDialogRef = null;
      });

      this._isHidden = false;
      return compRef;
    }
    // this.ueDialogRef = compRef.instance;
    // this.ueDialogRef.onPrivateKeyVerified.subscribe(() => {
    //   observer.next(this.ueDialogRef);
    // });
  }

  closeSignDialog() {
    this._isHidden = true;

    if (this.fusilliOverlayRef) {
      this.overlay.position().global().centerVertically().top('-1000px');
      this.fusilliOverlayRef.updatePosition();
      this.fusilliOverlayRef.updateSize({ width: 0, height: 0 });
    }
  }

  initSign(): Observable<void> {
    return this.euSignSub;
  }

  sign(data: string): Observable<string> {
    return this.euSignSub.pipe(
      mergeMap((temlateRef) => {
        if (!temlateRef) {
          return null;
        }

        return this.ueDialogRef.sign(data).map((res) => {
          if (!res) {
          }
          return res;
        });
      })
    );
  }

  public companyInfo(): Observable<any> {
    return this.euSignSub.pipe(
      map((temlateRef) => {
        if (!temlateRef) {
          return null;
        }

        return this.ueDialogRef.organization();
      })
    );
  }
}

import {
  EndUser,
  EndUserConstants,
  EndUserKeyMedia,
  EndUserSettings,
  EndUserSignInfo,
} from 'euscp';
import EncodeUtils from './encode-utils';

@Injectable({ providedIn: 'root' })
export class JsAgentSigner {
  private readonly EU_SIGN_INCLUDE_CONTENT_TIME_STAMP_PARAMETER =
    'SignIncludeContentTimeStamp';

  private _endUserLibraryType: EndUserConstants.EndUserLibraryType =
    EndUserConstants.EndUserLibraryType.JS;

  private euSign: EndUser;

  private euSettings: EndUserSettings = {
    language: 'uk',
    encoding: 'utf-8',
    httpProxyServiceURL:
      location.protocol + '//' + 'tax-proxy.turbopos.net/ProxyHandler.php',
    directAccess: true,
    CAs: '/assets/data/CAs.json',
    CACertificates: '/assets/data/CACertificates.p7b',
    allowedKeyMediaTypes: [
      'е.ключ ІІТ Алмаз-1К',
      'е.ключ ІІТ Кристал-1',
      'ID-карта громадянина (БЕН)',
      'е.ключ ІІТ Алмаз-1К (PKCS#11)',
      'е.ключ ІІТ Кристал-1 (PKCS#11)',
    ],
  };

  constructor() {}
  async isPrivateKeyReaded(): Promise<boolean> {
    if (!this.euSign) {
      return false;
    }

    return this.euSign.IsPrivateKeyReaded();
  }

  async IsInitialized(): Promise<boolean> {
    if (!this.euSign) {
      return false;
    }

    this.euSign.GetOwnCertificates();

    return this.euSign.IsInitialized();
  }

  GetOwnCertificates() {
    return this.euSign.GetOwnCertificates();
  }

  async readPrivateKey(
    privateKeyBase64: string,
    privateKeyFileName: string,
    password: string,
    caCN: string = null
  ): Promise<any> {
    var useFile =
      this._endUserLibraryType == EndUserConstants.EndUserLibraryType.JS;

    // var pkFileInput = useFile ? document.getElementById('pkFile') : null;
    // var passwordInput = document.getElementById(
    //   useFile ? 'pkFilePassword' : 'pkKeyMediaPassword'
    // );
    var selectedKM = null; // = useFile ? null : getSelectedKeyMedia();
    // var kmSelect = document.getElementById('pkKeyMediaSelect');
    /*
        Загальне ім'я ЦСК з списку CAs.json, який видав сертифікат для ос. ключа.
        Якщо null бібліотека намагається визначити ЦСК автоматично за 
        сервером CMP\сертифікатом. Встановлюється у випадках, коли ЦСК не 
        підтримує CMP, та для пришвидшення пошуку сертифіката ос. ключа
      */
    // var caCN = null;
    /*
        Сертифікати, що відповідають ос. ключу (масив об'єктів типу Uint8Array). 
        Якщо null бібліотека намагається завантажити їх з ЦСК автоматично з сервера CMP.
        Встановлюється у випадках, коли ЦСК не підтримує CMP, та для пришвидшення 
        пошуку сертифіката ос. ключа
      */
    var pkCertificates = null;

    if (useFile && (privateKeyBase64 == null || privateKeyBase64 == '')) {
      throw new Error('Не обрано файл з ос. ключем');
    }

    if (!useFile && !selectedKM) {
      throw new Error('Не обрано носій з ос. ключем');
    }

    if (password == null || password == '') {
      throw new Error('Не вказано пароль до ос. ключа');
    }

    let userOwnerInfo: any;
    if (useFile) {
      const privateKeyData = EncodeUtils.base64ToArrayBuffer(privateKeyBase64);
      if (privateKeyFileName.endsWith('.jks')) {
        const jksKeys = await this.euSign.GetJKSPrivateKeys(privateKeyData);
        if (!jksKeys.length) {
          throw { message: 'Ключ не знайдено' };
        }

        console.log('EndUser: jks keys got');
        userOwnerInfo = await this.euSign.ReadPrivateKeyBinary(
          jksKeys[0].privateKey,
          password,
          pkCertificates,
          caCN
        );
      } else {
        userOwnerInfo = await this.euSign.ReadPrivateKeyBinary(
          privateKeyData,
          password,
          pkCertificates,
          caCN
        );
      }
    } else {
      var keyMedia = new EndUserKeyMedia(selectedKM);
      keyMedia.password = password;
      userOwnerInfo = await this.euSign.ReadPrivateKey(
        keyMedia,
        pkCertificates,
        caCN
      );
    }

    return userOwnerInfo;
  }

  async initialize(): Promise<boolean> {
    this.euSign = this._getLibraryByType(
      EndUserConstants.EndUserLibraryType.JS
    );
    this.euSign.SetRuntimeParameter(
      this.EU_SIGN_INCLUDE_CONTENT_TIME_STAMP_PARAMETER,
      false
    );
    this.euSign.SetRuntimeParameter(
      EndUserConstants.EU_SIGN_TYPE_PARAMETER,
      EndUserConstants.EndUserSignType.CAdES_T
    );

    if (this._endUserLibraryType == EndUserConstants.EndUserLibraryType.JS) {
      const isInitialized = await this.euSign.IsInitialized();
      if (isInitialized) {
        console.log('EndUser: already initialized');
      } else {
        console.log('EndUser: initializing...');
        await this.euSign.Initialize(this.euSettings);
        console.log('EndUser: initialized');
      }
    } else {
      // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
      const libraryInfo: any = await this.euSign.GetLibraryInfo();
      if (!libraryInfo.supported) {
        throw (
          'Бібліотека web-підпису не підтримується ' +
          'в вашому браузері або ОС'
        );
      }

      if (!libraryInfo.loaded) {
        // Бібліотека встановлена, але потребує оновлення
        if (libraryInfo.isNativeLibraryNeedUpdate) {
          throw (
            'Бібліотека web-підпису потребує оновлення. ' +
            'Будь ласка, встановіть оновлення за посиланням ' +
            libraryInfo.nativeLibraryInstallURL
          );
        }

        // Якщо браузер підтримує web-розширення рекомендується
        // додатково до нативних модулів встановлювати web-розширення
        // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
        if (
          libraryInfo.isWebExtensionSupported &&
          !libraryInfo.isWebExtensionInstalled
        ) {
          throw (
            'Бібліотека web-підпису потребує встановлення web-розширення. ' +
            'Будь ласка, встановіть web-розширення за посиланням ' +
            libraryInfo.webExtensionInstallURL +
            ' та оновіть сторінку'
          );
        }

        // Бібліотека (нативні модулі) не встановлені
        throw (
          'Бібліотека web-підпису потребує встановлення. ' +
          'Будь ласка, встановіть бібліотеку за посиланням ' +
          libraryInfo.nativeLibraryInstallURL +
          ' та оновіть сторінку'
        );
      }

      const isInitialized = await this.euSign.IsInitialized();
      if (isInitialized) {
        console.log('EndUser: already initialized');
      } else {
        console.log('EndUser: initializing...');
        await this.euSign.Initialize(this.euSettings);
        console.log('EndUser: initialized');
      }
    }

    return true;
  }

  resetPrivateKey(): Promise<void> {
    return this.euSign.ResetPrivateKey();
  }

  async signData(data: string): Promise<string> {
    const win1251string = EncodeUtils.unicodeToWin1251(data);
    const win1251array = EncodeUtils.stringToArrayBuffer(win1251string);

    const result = await this.euSign.SignDataInternal(
      true,
      win1251array,
      false
    );

    var base64Str = await EncodeUtils.arraybufferToBase64Async(
      result as Uint8Array
    );
    return base64Str;
  }

  async verifyData(signedData: string): Promise<string> {
    console.log('VerifyDataInternal start');
    const arrayBuffer = EncodeUtils.base64ToArrayBuffer(signedData);
    const signInfo = await this.euSign.VerifyDataInternal(arrayBuffer);
    console.log('VerifyDataInternal finish');
    console.log(signInfo);

    let data: Uint8Array;
    const arrayResponse = <EndUserSignInfo[]>signInfo;
    if (arrayResponse.length > 0) {
      data = arrayResponse[0].data;
    } else {
      data = (<EndUserSignInfo>signInfo).data;
    }

    const result = await EncodeUtils.arraybufferToBase64Async(data);
    console.log('verifyData');
    console.log(window.atob(result));
    return window.atob(result);
  }

  async finalize(): Promise<void> {}

  async envelopData(data: string) {
    const response = await fetch('/assets/data/EK_C_NEW.cer');
    const cert = await response.arrayBuffer();
    const result = await this.euSign.EnvelopData(
      [new Uint8Array(cert)],
      data,
      false,
      true,
      false,
      true
    );
    return result;
  }

  private _getLibraryByType(type): any {
    if (this.euSign) {
      return this.euSign;
    }

    if (!this._endUserLibraryType) {
      this._endUserLibraryType = EndUserConstants.EndUserLibraryType.JS;
    }

    switch (type) {
      case EndUserConstants.EndUserLibraryType.JS:
        // Бібліотека для роботи з файловими ключами, що не потребує
        // встановлення додатково ПЗ
        return new EndUser(null, EndUserConstants.EndUserLibraryType.JS);

      case EndUserConstants.EndUserLibraryType.SW:
        var keyMedias = [];
        return new EndUser(null, EndUserConstants.EndUserLibraryType.SW);
    }
  }
}
