<div id="sign-widget-container" class="card mat-elevation-z8">
  <mat-toolbar>
    <span class="mr-4">Авторизація в податковій</span>
    <span class="material-icons" style="color: blue"> science </span>
    <small>Функція працює в тестовому режимі</small>
    <span class="flex-spacer"></span>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <!-- <div class="p-3">
    <div>
      <p>Оберіть тип носія ос. ключа</p>
      <input type="radio" id="pkTypeFile" name="pkType" checked />
      <label for="pkTypeFile">Файл</label>

      <input type="radio" id="pkTypeKeyMedia" name="pkType" />
      <label for="pkTypeKeyMedia">Носій ос. ключа</label>
    </div>

    <br />

    <div id="pkFileBlock">
      <label>Файл з ос. ключем:</label>
      <input id="pkFile" type="file" />
      <br />
      <label>Пароль:</label>
      <br />
      <input id="pkFilePassword" type="password" />
    </div>

    <div id="pkKeyMediaBlock" style="display: none">
      <label>Носій ос. ключа:</label>
      <select id="pkKeyMediaSelect"></select>
      <br />
      <label>Пароль:</label>
      <br />
      <input id="pkKeyMediaPassword" type="password" />
    </div>

    <br />

    <div id="signBlock" style="display: none">
      <label>Дані для підпису:</label>
      <br />
      <textarea
        id="data-textarea"
        style="width: 350px; height: 100px; overflow: scroll"
      ></textarea>
      <br /><br />
      <button id="sign-button">Накласти підпис</button>
      <br /><br />
      <label>Підписані дані:</label>
      <br /><br />
      <textarea
        id="sign-textarea"
        style="width: 350px; height: 100px; overflow: scroll"
      ></textarea>
    </div>
  </div> -->

  <div class="p-3">
    <div id="sign-widget-parent" style="width: 750px; height: 400px"></div>

    <div id="sign-info" style="width: 750px; height: 400px; display: none">
      <div *ngIf="subject.subjOrg">
        {{ subject.subjOrg }}
      </div>

      <div>
        {{ subject.subjTitle || subject.subjCN }}
      </div>

      <div *ngIf="subject.subjDRFOCode">
        {{ subject.subjDRFOCode }}
      </div>

      <div *ngIf="subject.subjEDRPOUCode">
        {{ subject.subjEDRPOUCode }}
      </div>

      <div *ngIf="certificates" class="p-3">
        <div class="row">
          <div *ngFor="let certificate of certificates" class="col-md-6">
            <div>
              {{ certificate.infoEx.issuerCN }}
            </div>
            <div>
              {{ certificate.infoEx.serial }}
            </div>
            <div>
              с
              {{
                certificate.infoEx.certBeginTime | date: 'dd.MM.yyyy HH:mm:ss'
              }}
              по
              {{ certificate.infoEx.certEndTime | date: 'dd.MM.yyyy HH:mm:ss' }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex-container pt-4">
        <button mat-raised-button (click)="close()">Закрить</button>
        <span class="flex-spacer"></span>
        <button mat-raised-button *ngIf="!saved" (click)="saveSign()">
          Сохранить
        </button>
        <button mat-button color="warn" *ngIf="saved" (click)="deleteSign()">
          Удалить
        </button>
      </div>
    </div>
  </div>
</div>
