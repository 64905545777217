<button mat-menu-item [matMenuTriggerFor]="languages">
  <mat-icon>language</mat-icon>
  <span>{{ 'language' | translate }}</span>
</button>
<mat-menu #languages="matMenu">
  <button
    mat-menu-item
    (click)="changeLang('uk')"
    [class.active]="currentLang == 'uk'"
  >
    УКР
  </button>
  <button
    mat-menu-item
    (click)="changeLang('ru')"
    [class.active]="currentLang == 'ru'"
  >
    РУС
  </button>
  <button
    mat-menu-item
    (click)="changeLang('en')"
    [class.active]="currentLang == 'en'"
  >
    ENG
  </button>
</mat-menu>

<style>
  .active {
    color: red;
  }
</style>
