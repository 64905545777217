import { Component } from '@angular/core';
import { LanguagesService } from '../languages.service';

@Component({
    selector: 'switch-language-button',
    templateUrl: 'switch-button.component.html'
})
export class SwitchButtonComponent {

    currentLang = 'uk';
    currentLangTitle = '';

    constructor(private _ls: LanguagesService) {
        this.currentLang = _ls.translate.currentLang;
        this.currentLangTitle = _ls.getCurrentLanguageShortTitle();
    }

    changeLang(lang: string) {
        this._ls.changeLang(lang);
        this.currentLang = lang;
        this.currentLangTitle = this._ls.getCurrentLanguageShortTitle();
    }
}
