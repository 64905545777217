import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StructureService } from '../services/structure.service';

import 'rxjs/add/operator/map';

@Injectable()
export class UserService {
    private accountUrl: string;
    private baseUserUrl: string;
    onRoomSelect: EventEmitter<any> = new EventEmitter<any>();
    onRoomSelected: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient, @Inject('API_ENDPOINT') private apiEndpoint: string,
        private structureService: StructureService) {

        this.accountUrl = `${this.apiEndpoint}/account`;
        this.baseUserUrl = `${this.apiEndpoint}/user`;
    }

    userInfo(userName: string) {
        return this.http.post<any>(`${this.accountUrl}/user-info`, { email: userName });
    }

    selectRoom(roomId: string) {
        this.onRoomSelect.emit();
        return this.http.post(`${this.baseUserUrl}/select-room/${roomId}`, {}).map(() => this.onRoomSelected.emit());
    }

    sendLoginCode(phoneNumber: string) {
        return this.http.post<any>(`${this.accountUrl}/send-login-code`, { phoneNumber });
    }

    sendSmsById(userId: string, message: string) {
        return this.http.post<any>(`${this.baseUserUrl}/sms`, { userId: userId, message: message });
    }

    resetPassword(code: string, email: string, password: string, confirmPassword: string) {
        return this.http.post<any>(`${this.accountUrl}/reset-password`, { code, email, password, confirmPassword });
    }
}
