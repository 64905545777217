import { NgModule, Injectable, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Observer } from 'rxjs';

interface Login {
  email?: string;
  phone?: string;
  mode?: string;
}

interface Account {
  login?: Login;
}

@Injectable()
export class AppStorageService {
  account: Account = {
    login: {},
  };

  scheduler = {
    selectedDoctor: {},
  };

  appointmentEdit: any;

  posGlobalsUpdate: Observable<any>;
  posGlobals: any = {};
  private posGlobalsObserver: Observer<any[]>;

  constructor() {
    this.posGlobalsUpdate = new Observable((observer: Observer<any[]>) => {
      this.posGlobalsObserver = observer;
    });
  }

  updatePosGlobals(globals: any) {
    const keys = Object.keys(globals);
    keys.forEach((key) => {
      this.posGlobals[key] = globals[key];
      const obj: any = {};
      obj[key] = globals[key];
      this.posGlobalsObserver.next(obj);
    });
  }
}

@NgModule({
  imports: [CommonModule],
})
export class AppStorageModule {
  static forRoot(): ModuleWithProviders<AppStorageModule> {
    return {
      ngModule: AppStorageModule,
      providers: [AppStorageService],
    };
  }
}
