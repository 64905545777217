import { Injectable, Injector, NgZone, isDevMode } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import EntityUtils from '../utils/entity-utils';
import { retry, catchError, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';
import { AuthTokenService } from '../services/auth-token.service';
import { RollbarService } from '../app.core.module';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private unauthorized = 401;
  private forbidden = 403;
  private badRequest = 400;
  private unknownError = 0;

  constructor(
    private injector: Injector,
    private _snackBar: MatSnackBar,
    private _tokenService: AuthTokenService,
    private _zone: NgZone
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const ticket = this._tokenService.getTicket();
    const sessionId = this._tokenService.webSessionId;

    if (ticket && ticket.access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${ticket.access_token}`,
          TimezoneOffset: (new Date().getTimezoneOffset() / 60).toString(),
          UserSessionId: sessionId || '',
        },
      });
    }

    const responseFormater = request.params.get('responseFormater');
    const skip = request.params.get('skip');
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const authService = this.injector.get(AuthService);
        const rollbar = this.injector.get(RollbarService);

        if (!isDevMode()) {
          rollbar.error(error);
        }

        let message = '';
        if (
          error.status &&
          (error.status === this.forbidden || error.status === this.forbidden)
        ) {
          this._zone.run(() =>
            this._snackBar.open('Не достатньо прав!', '', { duration: 5000 })
          );
        } else if (
          error.status &&
          (error.status === this.unauthorized ||
            error.status === this.unauthorized)
        ) {
          authService.companyLogout();
        } else if (error.status === 0 || error.status === 504) {
          if (request.params.get('skip')) {
            throwError(error);
          } else {
            this._zone.run(() =>
              this._snackBar.open(
                'Сбой подключения. Попробуйте повторить операцию.',
                '',
                {
                  duration: 7000,
                }
              )
            );
          }
        } else {
          if (error.status === this.badRequest) {
            const modelState = error.error.modelState;

            if (modelState) {
              // tslint:disable-next-line: no-shadowed-variable
              Object.keys(modelState).forEach((error: any) => {
                modelState[error].forEach((msg: string) => {
                  message += `${msg}\r\n`;
                });
              });
            }

            if (!message) {
              if (typeof error.error == 'string') {
                message = error.error;
              } else {
                message = error.error.Message || error.error.message;
              }
            }
          } else if (error.status === 500) {
            if (request.params.get('skip')) {
              throwError(error);
            } else {
              message =
                'Ошибка системы! Уведомление отправлено в сужбу поддержки.';
            } // error.error.exceptionMessage || error.error.Message;
          } else if (error.status === 404) {
            message = '';
          } else if (error.status === this.unknownError) {
            message = '';
          } else {
            message = error.message;
          }

          if (message) {
            this._zone.run(() => {
              this._snackBar.open(message, 'закрыть', { duration: 10000 });
            });
          }
        }

        return throwError(error);
      }),
      map((event: any) => {
        if (skip) {
          return event;
        }

        if (event instanceof HttpResponse) {
          let responseBody: any;
          if (typeof event.body === 'number') {
            responseBody = event.body;
          } else if (responseFormater == 'camelCase') {
            responseBody = EntityUtils.toCamel(event.body);
          } else if (responseFormater == 'none') {
            responseBody = event.body;
          } else {
            responseBody = EntityUtils.toLowerKeys(event.body);
          }

          event = event.clone({ body: responseBody });
        }

        return event;
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
})
export class JwtInterceptorModule {}
