import { Component, OnInit, Inject } from '@angular/core';

import { filter } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';

declare var gtag: any;
declare var sc: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  userInfo: any = {};

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _title: Title,
    private _meta: Meta,
    @Inject('APP_NAME') private appName: string
  ) {
    // Google Tags Manager
    //const navEndEvents = this._router.events.pipe(filter(event => event instanceof NavigationEnd));

    const navResolveEnd = this._router.events.pipe(
      filter((event) => event instanceof ResolveEnd)
    );
    // if (!isDevMode())
    //   navResolveEnd.subscribe((event: ResolveEnd) => {
    //     const activatedComponent = this.getActivatedComponent(event.state.root);
    //     if (activatedComponent)
    //       this.logPageView(
    //         `${activatedComponent.name} ${this.getRouteTemplate(
    //           event.state.root
    //         )}`,
    //         event.urlAfterRedirects
    //       );
    //   });

    //if (!isDevMode())
    //    navEndEvents.subscribe((event: NavigationEnd) => {
    //        gtag('config', 'G-M22K0NHZDH', {
    //            'page_path': event.urlAfterRedirects,
    //            'user_id': this.userInfo.userid
    //        });
    //    });
  }

  ngOnInit() {
    this._authService.userInfoSubscription.subscribe((res: any) => {
      this.userInfo = res;
      //this.setUserId(res.userid);

      //const data: any = {};
      //data.user = {
      //    name: this.userInfo.firstname,
      //    userid: this.userInfo.userid
      //};

      //localStorage.setItem('_sc_custom_data', JSON.stringify(data));
    });

    //if (!isDevMode())
    //    this.loadScript();

    this._title.setTitle(`${this.appName} POS`);
    //this._meta.removeTag("name='viewport'");

    //const body = <HTMLDivElement>document.body;
    //const script2 = document.createElement('script');
    //script2.innerHTML = '';
    //script2.src = '//morozenkomv-001-site6.itempurl.com/widget';
    //script2.async = true;
    //script2.defer = false;
    //script2.dataset['siteId'] = 'Hyetnb6546GH';
    //body.appendChild(script2);
  }

  //   private setUserId(userId: string) {
  //     this.appInsights.setAuthenticatedUserContext(userId, null, true);
  //   }

  //   private clearUserId() {
  //     this.appInsights.clearAuthenticatedUserContext();
  //   }

  //   private logPageView(name?: string, uri?: string) {
  //     this.appInsights.trackPageView({ name, uri });
  //   }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  private loadScript() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = '//code.jivosite.com/widget.js';
    script.async = true;
    script.defer = false;
    script.dataset['jvId'] = 'eHKzvUWrhg';
    body.appendChild(script);
  }
}
