import { NgModule, Injectable, Inject, ModuleWithProviders, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { SharedService } from './shared.service';

interface Ticket {
    access_token: string;
    module_path: string;
    expires_in: string;
    refresh_token: string;
    token_type: string;
    username: string;
    firstname: string;
    lastname: string;
    patronymic: string;
    email: string;
}

@Injectable()
export class AuthTokenService {
    private readonly authTicketKey = 'auth_ticket';
    //private readonly authImpersonatedTicketKey = 'auth_impersonated_ticket';

    private get _ticket(): Ticket {
        return JSON.parse(localStorage.getItem(this.authTicketKey)) as Ticket;
    }
    private set _ticket(ticket: Ticket) {
        if (!ticket)
            localStorage.removeItem(this.authTicketKey);

        localStorage.setItem(this.authTicketKey, JSON.stringify(ticket));
    }

    private get _impersonatedSessionId(): string {
        return sessionStorage.getItem('impersonated_web_session_id');
    }
    private set _impersonatedSessionId(sessionId: string) {
        if (!sessionId)
            localStorage.removeItem('impersonated_web_session_id');

        localStorage.setItem('impersonated_web_session_id', sessionId);
    }

    private get _webSessionId(): string {
        return sessionStorage.getItem('web_session_id');
    }
    private set _webSessionId(webSessionId: string) {
        if (!webSessionId)
            sessionStorage.removeItem('web_session_id');

        sessionStorage.setItem('web_session_id', webSessionId);
    }

    private get _lastSessionId(): string {
        return localStorage.getItem('last_web_session_id');
    }
    private set _lastSessionId(lastSessionId: string) {
        if (!lastSessionId)
            localStorage.removeItem('last_web_session_id');

        localStorage.setItem('last_web_session_id', lastSessionId);
    }

    constructor(private http: HttpClient,
        @Inject('API_ENDPOINT') private apiEndpoint: string,
        private sharedService: SharedService) {
    }

    requestToken(username: string, password: string, recapture?: string): Observable<Ticket> {
        return this.http.post<any>(`${this.apiEndpoint}/token`,
            `grant_type=password&username=${encodeURIComponent(username)}&password=${password}&recapture=${recapture}`)
            .map((ticket: Ticket) => this.setTicket(ticket));
    }

    startImpersonatedSession(companyId: string, userId: string): Observable<string> {
        return this.http.post<any>(`${this.apiEndpoint}/app-client/impersonated-session/${companyId}/${userId}`, {})
            .map((sessionId: string) => this._impersonatedSessionId = sessionId);
    }

    endImpersonatedSession(): Observable<Object> {
        return this.http.post<any>(`${this.apiEndpoint}/company/61E674C5-F44D-4A92-9945-2C1E0C32F587/end-impersonated-session`, {})
            .map((sessionId: string) => this._impersonatedSessionId = null);
    }

    refreshToken(): Observable<Ticket> {
        return this.http.post<any>(`${this.apiEndpoint}/token`,
            `grant_type=refresh_token&refresh_token=${this.getTicket().refresh_token}`)
            .map((ticket: Ticket) => this.setTicket(ticket));
    }

    hasTicket(): boolean {
        return !!this._ticket;
    }

    get webSessionId(): string {
        if (this._impersonatedSessionId)
            return this._impersonatedSessionId;

        if (this._webSessionId) {
            this._lastSessionId = this._webSessionId;
            return this._webSessionId;
        }

        if (this._lastSessionId)
            this._webSessionId = this._lastSessionId;

        return this._webSessionId;
    }
    set webSessionId(webSessionId: string) {
        this._webSessionId = webSessionId;
        this._lastSessionId = webSessionId;
    }

    getTicket(): Ticket {
        //if (this._impersonatedTicket)
        //    return this._impersonatedTicket
        //else
        return this._ticket
    }

    removeTicket() {
        localStorage.removeItem(this.authTicketKey);
        this.removeCompanyTokens();
    }

    removeCompanyTokens() {
        sessionStorage.removeItem('web_session_id');
        localStorage.removeItem('last_web_session_id');
    }

    private setTicket(ticket: Ticket) {
        if (!ticket || !ticket.access_token)
            this.sharedService.infoDialog('Authentication error', 'User not found');

        this._ticket = ticket;

        return ticket;
    }

    //private setImpersonatedTicket(ticket: Ticket) {
    //    if (!ticket || !ticket.access_token)
    //        this.sharedService.infoDialog('Authentication error', 'User not found');

    //    this._impersonatedTicket = ticket;

    //    return ticket;
    //}
}



import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule]
})
export class AuthenticationModule {
    static forRoot(): ModuleWithProviders<AuthenticationModule> {
        return {
            ngModule: AuthenticationModule,
            providers: [
                AuthTokenService,
                SharedService
            ]
        };
    }
}
